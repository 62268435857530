/* You can add global styles to this file, and also import other style files */

/* === Plugin styles === */

@import "~react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";


@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "../assets/styles/variables";
@import "~compass-mixins/lib/compass";
@import "~compass-mixins/lib/animate";
@import "~bootstrap/scss/bootstrap";
@import "../assets/styles/fonts";
@import "../assets/styles/functions";

/* === Icon fonts === */
@import "~@mdi/font/scss/materialdesignicons";


/* === Template mixins === */
@import "../assets/styles/mixins/animation";
@import "../assets/styles/mixins/badges";
@import "../assets/styles/mixins/buttons";
@import "../assets/styles/mixins/misc";
@import "../assets/styles/mixins/color-functions";
@import "../assets/styles/mixins/cards";
@import "../assets/styles/mixins/blockqoute";
@import "../assets/styles/mixins/popovers";
@import "../assets/styles/mixins/tooltips";
@import "../assets/styles/mixins/no-ui-slider";


/* === Core Styles === */
@import "../assets/styles/background";
@import "../assets/styles/typography";
@import "../assets/styles/reset";
@import "../assets/styles/responsive";
@import "../assets/styles/misc";
@import "../assets/styles/utilities";
@import "../assets/styles/demo";
@import "../assets/styles/spinner";
@import "../assets/styles/dashboard";

/* === Components === */

@import "../assets/styles/components/forms";
@import "../assets/styles/components/checkbox-radio";
@import "../assets/styles/components/icons";
@import "../assets/styles/components/tables";
@import "../assets/styles/components/buttons";
@import "../assets/styles/components/breadcrumbs";
@import "../assets/styles/components/cards";
@import "../assets/styles/components/preview";
@import "../assets/styles/components/user-profile";
@import "../assets/styles/components/lists";
@import "../assets/styles/components/bootstrap-progress";
@import "../assets/styles/components/tabs";
@import "../assets/styles/components/dropdowns";
@import "../assets/styles/components/pagination";
@import "../assets/styles/components/loaders/loaders";
@import "../assets/styles/components/timeline";
@import "../assets/styles/components/spinner";
@import "../assets/styles/components/react-table";

@import "../assets/styles/components/landing-screens/auth";

/* === Plugin Overrides === */
@import "../assets/styles/components/plugin-overrides/react-bootstrap-table";
@import "../assets/styles/components/plugin-overrides/react-table";

/* === Layout === */
@import "../assets/styles/navbar";
@import "../assets/styles/sidebar";
@import "../assets/styles/footer";
@import "../assets/styles/layout";
@import "../assets/styles/settings-panel";
@import "../assets/styles/rw";
/*CSS from designer  */
//@import "../assets/styles/rwcustom";
/* block end*/

/*RW : Custome Code*/
.nav {
  //padding-left: 10px;  /* Code modified, for meeting the designer expectation*/
}
.hidden {
    display: none;
  }
.inline-errormsg {
    color: red;
    font-size: 12px;
    display: block;
    font-family: $gen-font-family !important;
  }
.marginleft{

margin-left: 0px !important;
}
.fontcolorgrey{
color:#76838f !important;
}
.topMrg{
/* Code modified, for meeting the designer expectation*/
//margin-top: 25px !important;
margin-top: 5px !important;
/*End*/

}
.leftMrg{
margin-left: 270 !important;
}
.rwsidebar{
padding-left: 0px;
// for -/+
//background: linear-gradient(0deg,#5768f3,#232428);  /* Commented code, for meeting the designer expectation*/
z-index: 1050 !important;
height: 100%;
}
.col-lg-10{
  padding-left: 0px;
 
}

.dataTables_paginate{
margin-left:57.6%;}

.react-bootstrap-table-pagination-total
{
padding: 10px;
}


.react-bootstrap-table{
  overflow: auto;
}
/*RW custom code end*/

// for side bar color
.sidebar {
  background: linear-gradient(180deg, #fefefe 0%, #f3f8fa 100%);//#f7fafb; //linear-gradient(0deg,#5768f3,#232428); /* Commented code, for meeting the designer expectation*/
  border-right: 1px solid #e6e6e6;
  // Designer code
  /*
  position: static;
  width: 253px;
  height: 783px;
  top: 0;
  left: 0;
  background: url(../assets/images/custom/372b7cd6-f645-4ad9-b331-fc107699800b.png) 
  no-repeat center;
  background-size: cover;
  border-right: 1px solid #e6e6e6;
  z-index: 291;
  */
}
.content-wrapper {
  /* Commented code, for meeting the designer expectation*/
  //background: linear-gradient(0deg, #0e27f000, #d3d3d5);

}
// end
.main-panel {
  padding-top: 45px;
  //margin-left: 0;
  //padding-right: 0%;
}
.page-header {
  padding-bottom: 0;
}
// for remove extra marging from card
.card .card-body {
  //padding: 1rem 1.25rem;
  padding: 1rem 1rem 2px 1.25rem;
}
.card .card-description {
  margin-bottom: 0;
  width: 95%;
  display: inline;
}
// end
// for extra margin from help icon
.float-right {
  float: right !important;
  width: 5%;
  margin-right: 2px;
}
// end
// for remove extra margin from button
.btn-lg, .btn-group-lg > .btn {
  padding: 0.8rem 0.6rem;
}
// end
.form-group {
  margin-bottom: 1rem;
}

.btn-default-bg:not(:has(:not(img))){
  background-color: $button-df-bg !important;
  color: #fff!important;
}

.btn-primary:not(:has(:not(img))){
  background-color: #fff; //$button-bg !important;
  border-color:#c7c5c5 !important; //#e6e6e6 !important;
  border-radius: 5px;
  color: #2e2e2e;//#fff !important;
  font-family: $gen-font-family !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.rw-btn-primary:not(:has(:not(img))){
  background-color: #fff; //$button-bg !important;
  border-color:#c7c5c5 !important; //#e6e6e6 !important;
  border-radius: 5px;
  color: #2e2e2e;//#fff !important;
  font-family: $gen-font-family !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.rw-btn-bg-gray{
  background-color: #fff !important;
  border-color: #c7c5c5 !important;
  color: #2e2e2e !important;
  font-family: $gen-font-family !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

//.btn-primary,.custom-file .custom-file-label::after,.react-bootstrap-table-pagination .react-bs-table-sizePerPage-dropdown .dropdown-toggle 
.custom-file .custom-file-label::after,.react-bootstrap-table-pagination .react-bs-table-sizePerPage-dropdown .dropdown-toggle {
  //background-color: #3141bf; /* Code commented for meeting the designer expectation*/
  background-color: $container-bg !important;
  color: $title-color !important;
  border-color: #e6e6e6 !important;
  padding: 0.6rem 0.6rem;

}
.pagination .page-item.active .page-link, .pagination .page-item:hover .page-link, .pagination .page-item:focus .page-link, .pagination .page-item:active .page-link {
 // background: #3141bf; // /* Code commented for meeting the designer expectation*/
 background-color: $container-bg !important;
 color: $title-color !important;
 border-color: #424141 !important;
}


// for small the pagination button
.dropdown-menu{
  min-width: 0;
}
/*Code for override the icon of pagination*/
.pagination .page-item:hover .page-link {
  border-color: #424141 !important;
  border-radius: 4px;
}
.pagination .page-item:focus .page-link {
  border-radius: 4px !important;
}
.react-bootstrap-table-pagination .react-bs-table-sizePerPage-dropdown .dropdown-toggle:hover {
  color: $title-color !important;
  background-color: $container-bg !important;
  border-color: #424141 !important;
}
.react-bootstrap-table-pagination .react-bs.table-sizePerPage-dropdown .dropdown-toggle:focus{
  border-color: 1px solid #212121 !important;
  color: $title-color !important;
  }
  .dropdown-menu.open.show >.dropdown-item> a{
    color: $title-color !important;
  }
// end
// for prevent the screen blinking on popup open
.modal-open{
  padding-right: 0px !important;
}

.onSle:focus-visible {
  outline: 2px solid black;
}

// for dark color of the selected option of list
.css-cvhtoe-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {
    background-color: rgba(25, 118, 210, 0.38) !important;
  }
 // end code


.bgcolor_lightgreen {
  background: #c6ecc6;
}
.bgcolor_lightblue {
  background: #cce6ff;
}


select.form-control {
  color: #000000;
}